/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { Loader } from '@yardstik/core.components';
import { StepForm, CandidateApplyCompleted, FormProps } from '@yardstik/core.components';
import { removeStepComponentFile, setComponentFile } from '../../redux/candidateApply/formSections/reducer';
import {
  ADD_IMAGE_TO_SECTION,
  ADD_CANDIDATE_FORM_REPEATER_SECTION,
  ADD_IMAGE_TO_REPEATER_SECTION,
  CLEAR_DESTORY_ADDRESS_ID,
  REMOVE_CANDIDATE_FORM_REPEATER_SECTION
} from '../../redux/candidateApply/actionTypes';
import { CandidateApplyProps, FormFieldActionProps } from '../CandidateApply/candidateApplyInterfaces';
import { decrementStep, setCandidateApplicationLoading } from '../../redux/candidateApply/controls/actions';
import {
  onNextStep,
  parseCandidateSubmit,
  getRemovedAddressId,
  updateDefaultDataFromRes
} from './candidateApplyUtilsBeta';
import {
  handleUpdateAppLevelFormData,
  removeImage,
  addAddressToDestroy,
  updateFieldValue
} from '../../redux/candidateApply/formValues/actions';
import { setApplicationPaid } from '../../redux/candidateApply/application/actions';
import removeObjectKey from '../../utils/removeObjectKey/index';
import CandidateApplyUnavailable from '../CandidateApplyUnavailable';
import { removeFile } from '../../services/api/fileUpload';
import { setControlsError } from '../../redux/controls/actions';
import { Styles } from '../../redux/candidateApply/styles/reducer';
import { RootState } from '../../redux/store';

import { useAuth0 } from '../../contexts/auth0/auth0-context';
import { getCandidatePatchApiPath, getinvitationsPatchApiPath } from '../../services/auth/authHelpers';

import { identityVerificationStatusPolling } from './identityVerificationStatusPolling';

type ApplicationType = {
  account_id?: string;
  report_id?: string;
  invitation_id?: string;
  paid?: boolean;
  pii?: boolean;
  paid_by: 'account' | 'candidate';
  account?: {
    account_name?: string;
  };
};

const VOUCHED_APP_ID = process.env.REACT_APP_VOUCHED_APP_ID || '';

const CandidateApply = (props: CandidateApplyProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    candidateId = '',
    reportId = '',
    submitForm = () => ({}),
    onFileUpload = () => ({}),
    submitNextStep = () => ({}),
    sendVerificationEmail,
    submitVerificationEmailCode,
    connectPaymentService,
    stripeKey = '',
    redirectLink = null,
    refetchApplyData = () => Promise.resolve(),
    useOnNextStep = false
  } = props;

  const { isAuthenticated } = useAuth0();

  //REDUX DATA
  const isRepeaterActionsDisabled = useSelector(
    (state: RootState) => state.reports?.reportDetail?.status === 'info_requested'
  );

  const formValues = useSelector(state => get(state, 'candidate_application.formValues', {}));

  const candidateEmail = useSelector(state => get(state, 'candidate_application.candidate.email', ''));
  const defaultEmail = useSelector(state => get(state, 'candidate_application.formValues.emailVerification.email', ''));
  const accountId = useSelector(state => get(state, 'candidate_application.account.account_id', ''));

  const defaultAdcRaw = useSelector(state => get(state, 'adcValues.values', {}));
  const defaultAdc = removeObjectKey(defaultAdcRaw, 'adc_');

  const adcTrigger = useSelector(state => get(state, 'adcValues.triggerKey', 0));

  useEffect(() => {
    // if adc is triggered for dataCollection
    // this is triggered from incrementAdcTrigger action in updateDefaultDataFromRes()
    if (adcTrigger > 0) {
      const defaultAdcKeys = Object.keys(defaultAdc) || [];
      if (defaultAdcKeys && Array.isArray(defaultAdcKeys) && defaultAdcKeys.length > 0) {
        defaultAdcKeys.forEach((tu: any) => {
          updateFieldValue(tu, defaultAdc[tu] || '', 'dataCollection', dispatch);
        });
      }
    }
    // intentionally disabled for adc functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adcTrigger]);

  const country = useSelector(state =>
    get(state, 'candidate_application.application.workflow_steps.data_collection.country', 'US')
  );
  const candidateValues = useSelector(state => get(state, 'candidate_application.formValues.dataCollection', {}));
  const formSteps = useSelector(state => get(state, 'candidate_application.formSections.steps', []) as FormProps[]);
  const application = useSelector(state => get(state, 'candidate_application.application', {})) as ApplicationType;
  const account = useSelector(state => get(state, 'candidate_application.account', {})) as { account_id: string };
  const currentStep = useSelector(state => get(state, 'candidate_application.controls.currentStep', 0));

  const loading = useSelector(state => get(state, 'candidate_application.controls.loading', false));

  const candidateApplicationStore = useSelector(state => get(state, 'candidate_application', {}));

  const status = useSelector(state => get(state, 'candidate_application.controls.status', 'unavailable'));

  const hasCourses = useSelector(state => get(state, 'candidate_application.application.course.has_courses', false));
  const coursesComplete = useSelector(state =>
    get(state, 'candidate_application.application.course.courses_completed', false)
  );

  const screenings = useSelector(state =>
    get(state, 'candidate_application.application.workflow_steps.screenings', [])
  );

  const stylesData = useSelector(state => get(state, 'candidate_application.styles', {})) as Styles;
  const { pii = false, paid = false, paid_by } = application;

  const repeaterSectionOnAdd = (propsSent: FormFieldActionProps) => {
    const { name = '', formName = '' } = propsSent;
    dispatch({
      type: ADD_CANDIDATE_FORM_REPEATER_SECTION,
      payload: { fieldName: name, formName }
    });
  };

  const handleApplicationPaid = React.useCallback(() => {
    setApplicationPaid(true, dispatch);
  }, [dispatch]);

  const repeaterSectionOnRemove = (propsSent: FormFieldActionProps, index: number) => {
    const { name = '', formName = '' } = propsSent;
    // if removing addresses we need to capture the address ID in order to destoy it in the DB
    if (name === 'addresses') {
      const addressesValue = get(propsSent, 'value', []);
      const addressId = getRemovedAddressId(addressesValue, index);
      addAddressToDestroy(dispatch, addressId);
    }
    dispatch({
      type: REMOVE_CANDIDATE_FORM_REPEATER_SECTION,
      payload: { fieldName: name, formName, index }
    });
  };

  const componentReachedFilesLimit = ({ stepName, componentName, filesLimit }) => {
    const stepIndex = formSteps.findIndex(step => step.formName === stepName);
    const stepData = formSteps[stepIndex] || {};
    const stepDataComponents = stepData.components || [];
    const componentIndex = stepDataComponents.findIndex(component => component.name === componentName);

    if (componentIndex === -1) {
      return false;
    }

    const { fileObjects = [] } = stepDataComponents[componentIndex];

    return fileObjects.length >= filesLimit;
  };

  type FileObjectType = {
    readonly file: File;
    readonly data: string | ArrayBuffer | null;
    mediaId: string;
  };
  const onAddImage = useCallback(
    async (propsSent: FormFieldActionProps, files: FileObjectType[]) => {
      const { name = '', confidential_file = false, filesLimit = 1, formName } = propsSent;

      const reachedFilesLimit = componentReachedFilesLimit({
        stepName: formName,
        componentName: name,
        filesLimit
      });

      if (reachedFilesLimit) {
        dispatch(
          setControlsError({
            status: 'error',
            message: 'You reached the file limit, remove some of the previous files to upload new ones'
          })
        );
        return;
      }

      const publish_images_to_process = get(propsSent, 'step.data_collection.publish_images_to_process', '');
      const document_test = get(propsSent, 'step.data_collection.document_test');
      const { file } = files[0];
      const formData = new FormData();
      formData.set('file', file);
      formData.set('report_id', reportId);
      formData.set('category', name);
      formData.set('publish_images_to_process', publish_images_to_process);

      formData.set('screening_name', formName);
      formData.set('screening_file_group', name);

      if (document_test) {
        formData.append('document_test', document_test);
      }

      if (confidential_file) {
        formData.append('data_classification', 'confidential_file');
      }

      const imageResponse = await onFileUpload(candidateId, formData, propsSent);

      if (imageResponse) {
        const mediaId = imageResponse.id;
        const newFile = { ...files[0], mediaId };

        dispatch(
          setComponentFile({
            componentName: name,
            newFile,
            stepName: formName
          })
        );

        const fileData = {
          mime_type: file.type,
          file_reference: mediaId,
          file_name: file.name,
          media_id: mediaId
        };

        const { repeaterSectionIndex = null, repeaterSectionName = null } = propsSent;
        const type =
          repeaterSectionIndex !== null && repeaterSectionName ? ADD_IMAGE_TO_REPEATER_SECTION : ADD_IMAGE_TO_SECTION;
        dispatch({
          type,
          payload: { propsSent, file: fileData }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [formSteps, dispatch, candidateId, currentStep, reportId, onFileUpload]
  );

  const onRemoveImage = useCallback(
    async (propsSent: FormFieldActionProps, fileObject: FileObjectType) => {
      const { name = '', formName } = propsSent;
      const { mediaId } = fileObject;
      const payload = {
        report_id: reportId,
        screening_name: formName,
        screening_file_group: name
      };

      await removeFile({ candidateId, mediaId, payload });

      removeImage(dispatch, mediaId, propsSent);

      dispatch(
        removeStepComponentFile({
          componentName: name,
          stepName: formName,
          mediaId
        })
      );
    },
    [candidateId, reportId, dispatch]
  );

  const executeNextStep = async () => {
    setCandidateApplicationLoading(true, dispatch);
    const lastStep = formSteps.length - 1;
    const isLastButOneStep = currentStep === lastStep - 1;

    try {
      if (formSteps[currentStep].formName === 'IdentityVerification') {
        await identityVerificationStatusPolling({
          reportId,
          candidateId
        });
      }

      await submitNextStep(currentStep, formSteps);

      // if we are on the last but one step we need to use the on next step function to advance the step
      // to avoid calling refetch again as the refetch will return an error because the report is already submitted
      if (isLastButOneStep) {
        onNextStep(currentStep, formSteps, paid, pii, paid_by, screenings, dispatch);
        return setCandidateApplicationLoading(false, dispatch);
      }

      // if we are NOT on the last but one step we want to refetch the data
      await refetchApplyData();

      /**
       * REMOVE when NON_AUTH0_FLOW is not used anymore
       * the non-auth0-flow needs to use the on next step function to advance the step
       * so, this IF can be removed when the non-auth0-flow is not used anymore
       */
      if (useOnNextStep) {
        onNextStep(currentStep, formSteps, paid, pii, paid_by, screenings, dispatch);
      }

      setCandidateApplicationLoading(false, dispatch);
    } catch (error) {
      console.error('Error while executing next step', error);
    }
  };

  const onSubmitForm = async res => {
    const { formName } = res;
    const { report_id: reportId = '', invitation_id: invitationId = '' } = application;
    const { account_id: accountId = '' } = account;
    reportId;

    // this checks whether there is an invitation ID, so that you are either updating an invitation or candidate
    // patchURL determines which you are updating
    let patchURL = '';
    const candidateToSend = parseCandidateSubmit(formName, candidateApplicationStore);
    if (invitationId) {
      patchURL = getinvitationsPatchApiPath(isAuthenticated, invitationId);
    } else {
      //Set patchURL based on if the user is authenicated
      patchURL = getCandidatePatchApiPath(isAuthenticated, candidateId);
    }

    await submitForm(candidateToSend, patchURL, formName, accountId, reportId)
      .then((res: any) => {
        // need to clear out destoryed address id because they no longer exist in the db
        dispatch({
          type: CLEAR_DESTORY_ADDRESS_ID
        });
        // This updates candidate in redux store with revised candidate
        updateDefaultDataFromRes(candidateToSend, res, dispatch);
        executeNextStep();
        window.scrollTo(0, 0);
      })
      .catch((err: any) => {
        throw err;
      });
  };

  const updateAppLevelFormData = (value = null, fieldProps = {}) => {
    handleUpdateAppLevelFormData(value, fieldProps, dispatch);
  };

  const showIDVerificationError = () => {
    history.push('/candidate/apply/id-verification-error');
  };

  let candidateApplyBody: JSX.Element;

  if (status === 'available') {
    const firstName = get(candidateValues, 'first_name', '');
    const middleName = get(candidateValues, 'middle_name', '');
    const lastName = get(candidateValues, 'last_name', '');
    const signatureValues = {
      first_name: firstName,
      middle_name: middleName === null ? '' : middleName,
      last_name: lastName
    };

    candidateApplyBody = (
      <div>
        <StepForm
          formSteps={formSteps}
          values={formValues}
          updateAppLevelFormData={updateAppLevelFormData}
          currentStep={currentStep}
          submitButtonText={'Next Step'}
          secondaryButtonText={'Back'}
          secondaryAction={() => decrementStep(dispatch)}
          onSubmit={onSubmitForm}
          onNextStep={() => executeNextStep()}
          logo={stylesData?.logo}
          onAddRepeater={repeaterSectionOnAdd}
          onRemoveRepeater={repeaterSectionOnRemove}
          onAddImage={onAddImage}
          onRemoveImage={onRemoveImage}
          signatureValues={signatureValues}
          sendVerificationEmail={sendVerificationEmail}
          submitVerificationEmailCode={submitVerificationEmailCode}
          connectPaymentService={connectPaymentService}
          stripeKey={stripeKey}
          vouchedAppId={VOUCHED_APP_ID}
          redirectLink={redirectLink}
          isRepeaterActionsDisabled={isRepeaterActionsDisabled}
          country={country}
          candidateEmail={candidateEmail}
          paid={paid}
          handleApplicationPaid={handleApplicationPaid}
          defaultEmail={defaultEmail}
          accountId={accountId}
          hasCourses={hasCourses}
          coursesComplete={coursesComplete}
          showIDVerificationError={showIDVerificationError}
          primaryColor={stylesData?.invitation?.primary_color}
        />
        {loading && <Loader overlay={true} spinnerColor="primary" />}
      </div>
    );
  } else if (status === 'completed') {
    candidateApplyBody = (
      <CandidateApplyCompleted redirectLink={redirectLink} hasCourses={hasCourses} coursesComplete={coursesComplete} />
    );
  } else {
    // This should probably pass in props; is this ever used?
    candidateApplyBody = <CandidateApplyUnavailable />;
  }
  return candidateApplyBody;
};

export default CandidateApply;
